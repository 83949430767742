import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";

export const supportedLanguages = [
    // {code: "en", name: "EN"},
    {code: "de", name: "DE"}
]

i18n
    // Add React bindings as a plugin.
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    // Initialize the i18next instance.
    .init({
        fallbackLng: "de",
        supportedLngs: supportedLanguages.map(it => it.code),
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
