import {useLivePreview} from '@payloadcms/live-preview-react';
import {useParams} from 'react-router-dom'
import {useState} from 'react';
import {ReleaseNote} from '@amcon/release-notes-api'
import {ReleaseNoteComponent} from '../../release-note/ReleaseNote'

const useReleaseNotes = (id: string | undefined) => {

    const [loaded, setLoaded] = useState(false)
    const [releaseNote, setReleaseNote] = useState<ReleaseNote | null>(null);

    if (!loaded) {

        fetch(
            `/api/release-notes?where[id][equals]=${id}`
        ).then(res => res.json())
            .then(res => {
                setReleaseNote(res?.docs[0] ?? null)
            })
        setLoaded(true)
    }

    return {releaseNote}
}

export function PreviewComponent() {

    const {id} = useParams()
    const {releaseNote} = useReleaseNotes(id)

    if (releaseNote == null)
        return <div style={{margin: '20px 0'}}>Not found</div>

    return <Preview initial={releaseNote}></Preview>
}

function Preview({initial}: { initial: ReleaseNote }) {

    const {data} = useLivePreview<ReleaseNote>({
        initialData: initial,
        serverURL: "/"
    })

    return <ReleaseNoteComponent item={data}></ReleaseNoteComponent>
}

type Children = Leaf[]

type Leaf = {
    type: string
    value?: {
        url: string
        alt: string
    }
    children: Children
    url?: string
    [key: string]: unknown
}