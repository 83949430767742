import {PayloadImageMetaData} from '@amcon/cms-blocks'
import style from "./SliderComponent.module.scss"
import {useEffect, useState} from 'react'

export type SliderComponent = {
    images: PayloadImageMetaData[],
    delay: number,
    duration: number
}

export const SliderComponent = ({images, duration = 1000, delay = 5000}: SliderComponent) => {

    const [index, setIndex] = useState(0)
    const [animate, setAnimate] = useState(false)

    useEffect(() => {
        const interval = setInterval(() => {
            setAnimate(true)
            setTimeout(() => {
                setAnimate(false)
                setIndex(index => index + 1 >= images.length ? 0 : index + 1)
            }, duration)
        }, delay)

        return () => clearInterval(interval)
    }, [images, setIndex, setAnimate, delay, duration]);

    function getOrder(image: number) {

        if (index === 0)
            return image < images.length - 1 ? 1 : null
        else
            return image < index - 1 ? 1 : null
    }

    return <div className={`${style.slider}${animate ? ` ${style.move}` : ''}`} data-index={index}>
        <div className={style.content} style={{animationDuration: `${duration / 0.8}ms`}}>
            {images.map((it, i) => <picture key={i} style={{order: getOrder(i)}}>
                <img loading={'lazy'} src={it.url} alt={it.alt} width={it.width} height={it.height}/>
            </picture>)}
        </div>
    </div>
}