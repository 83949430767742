import {MouseEvent, PropsWithChildren, ReactNode, useEffect, useState} from "react"
import style from "./CustomSelect.module.scss"

export type CustomSelectOption<T> = {
    value: T,
    onSelect: (value: T) => void
}

const CustomSelectOptionComponent = <T, >({children, value, onSelect}: PropsWithChildren<CustomSelectOption<T>>) => {
    return <div className={style.option} onClick={e => {
        e.stopPropagation()
        onSelect(value)
    }}>{children}</div>
}

export type CustomSelectProps<T> = {
    onChange: (value: T | null) => void
    option?: (value: T | null, display: boolean) => ReactNode
    items: T[]
    value?: T | null
}

export const CustomSelect: React.FC<CustomSelectProps<any>> = <T, >(props: PropsWithChildren<CustomSelectProps<T>>) => {

    const [value, setValue] = useState<T | null>(props.value || props.items[0] || null)
    const [open, setOpen] = useState(false)

    function toggle(event: MouseEvent<HTMLDivElement>) {
        setOpen(!open)
        event.stopPropagation()
    }

    const display = <div className={style.display} onClick={toggle}>
        {props.option!(value, true)}
        <span className={style.arrow + ' material-symbols-rounded'}>expand_more</span>
    </div>

    function select(value: T) {
        setValue(value)
        setOpen(false)
        props.onChange(value)
    }

    function close() {
        setOpen(false)
    }

    useEffect(() => {
        if (open)
            window.addEventListener("click", close)
        else
            window.removeEventListener("click", close)
        return () => window.removeEventListener("click", close)
    }, [open]);

    return <div className={`${style.select}${open ? ` ${style.open}` : ''}`}>
        {display}

        <div className={style.options}>
            {display}
            {props.items.map((it, i) =>
                <CustomSelectOptionComponent key={i} value={it} onSelect={select}>{props.option!(it, false)}</CustomSelectOptionComponent>)}
        </div>
    </div>
}

CustomSelect.defaultProps = {
    option: (value, _) => value?.toString()
}