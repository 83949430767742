import {TextInlineNode} from '../content'

const IS_BOLD = 1;
const IS_ITALIC = 1 << 1;
const IS_STRIKETHROUGH = 1 << 2;
const IS_UNDERLINE = 1 << 3;
const IS_CODE = 1 << 4;
const IS_SUBSCRIPT = 1 << 5;
const IS_SUPERSCRIPT = 1 << 6;
const IS_HIGHLIGHT = 1 << 7;

export const TextComponent = ({item}: { item: TextInlineNode }) => {

    if (!item.text)
        return <>&nbsp;</>

    const classList: string[] = ['payload-block-text']

    if (item.format & IS_BOLD)
        classList.push("payload-block-text-bold")
    if (item.format & IS_ITALIC)
        classList.push("payload-block-text-italic")
    if (item.format & IS_STRIKETHROUGH)
        classList.push("style.strikethrough")
    if (item.format & IS_UNDERLINE)
        classList.push("payload-block-text-underline")
    if (item.format & IS_CODE)
        classList.push("payload-block-text-code")
    if (item.format & IS_SUBSCRIPT)
        classList.push("payload-block-text-subscript")
    if (item.format & IS_SUPERSCRIPT)
        classList.push("payload-block-text-superscript")
    if (item.format & IS_HIGHLIGHT)
        classList.push("payload-block-text-highlight")

    return <span className={classList.join(' ')}>{item.text}</span>
}