import style from "./ReleaseNote.module.scss"
import {isEmpty, RootNode, serialize} from "@amcon/cms-blocks";
import {ReleaseNote} from "@amcon/release-notes-api";
import logo from "./amcon_logo_weiss.svg";
import '@amcon/cms-blocks/dist/index.css'
import {useTranslation} from 'react-i18next'

const additionalBlock = (content: RootNode | undefined | null, header: React.ReactElement) => {

    if (isEmpty(content))
        return null

    return <div className={style.block}>
        <div className={style.tag}>
            {header}
        </div>
        {serialize(content.root.children)}
    </div>
}

export function ReleaseNoteComponent({item}: { item: ReleaseNote }) {

    const {t} = useTranslation()
    const draft = (item._status === 'draft' || item.version._status === 'draft') && <div className={style.tag}>
        <span className="material-symbols-rounded">unpublished</span>
        Unveröffentlicht
    </div>

    return <article className={`${style['release-note']} card`}>
        <div className={style.top}>
            <div>
                <span>{item.product?.name}</span>
                <span className={style.divider}>|</span>
                <span>{item.module?.name}</span>
            </div>
            <div>
                {item.license && <>
                    <span className={'material-symbols-rounded'}>verified</span>
                    <span>Lizenz</span>
                    <span className={style.divider}>|</span>
                    <span>{item.license.name}</span>
                </>}
            </div>
        </div>
        <div className={style.header}>
            <div className={style.title}>
                {item.loggedIn ?
                    <a href={'/admin/collections/release-notes/' + item.id} target={'_blank'} title={'In Payload öffnen'}>{item.title}</a>
                    : item.title}
            </div>
            <div>{draft}</div>
            <div className={style.type + ' ' + style[item.type.id]}>{item.type.name}</div>
        </div>
        <div className="release-notes-root">
            {serialize(item.content?.root?.children || [])}
            <div className={style.blocks}>
                {additionalBlock(item.settings,
                    <><span className={'material-symbols-rounded'}>settings</span>Einstellung</>)}
                {additionalBlock(item.permissions,
                    <><span className={'material-symbols-rounded'}>verified_user</span>Berechtigung</>)}
                {additionalBlock(item.internal,
                    <><img src={logo} alt={'AMCON'}/> Nur intern sichtbar</>)}
            </div>
        </div>
        <div className={style.footer}>
            <div className={style.version}>{t('ReleaseNote.Version.Text', {version: item.version.name})}</div>
        </div>
    </article>
}