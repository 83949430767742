import {useContext, useEffect, useState} from "react";
import ReleaseNotesServiceContext from '../../ReleaseNotesServiceContext'
import {Pagination, Product, ReleaseNote, Type, Version} from '@amcon/release-notes-api'
import style from "./SummaryComponent.module.scss";
import {Paginator} from '../../release-note/paginator/Paginator'
import {NavLink, useSearchParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import AppContentContext from '../../appContentContext'

type Group = {
    product: Product
    version: Version
    types: { type: Type, items: ReleaseNote[] }[]
}

export const SummaryComponent = () => {
    const {t, i18n} = useTranslation();

    const [searchParams, setSearchParams] = useSearchParams()
    const [elements, setElements] = useState<Pagination<Group>>(null)
    const service = useContext(ReleaseNotesServiceContext)
    const appContent = useContext(AppContentContext)

    const page = parseInt(searchParams.get("page")) || 1
    const limit = parseInt(searchParams.get("limit")) || 10;

    function setPage(page: number) {

        if (page === parseInt(searchParams.get("page")) || (page === 1 && !searchParams.has("page")))
            return

        setSearchParams(p => {
            p.set("page", page.toString())
            return p;
        })
    }

    function scrollTop() {
        window.scroll({top: appContent.current.offsetTop})
    }

    useEffect(() => {
        service.getSummary(limit, page, i18n.resolvedLanguage).then(setElements)
    }, [limit, service, page, i18n.resolvedLanguage]);

    return <div className={style.root}>
        {elements?.items?.map(group => <article key={group.product.id} className={'card'}>
            <h1 className={style.title}>{group.product.name}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;{t('Summary.Version.Header', {version: group.version.name})}</h1>
            {group.types.map(it => <div key={it.type.id}>
                <h2 className={style.type}>{it.type.name}</h2>
                <ul className={style.list}>
                    {it.items.map(it => <li key={it.id}>{it.title}</li>)}
                </ul>
            </div>)}
            <NavLink className={style.more} to={`/list?version=${group.version.id}&product=${group.product.id}`} onClick={scrollTop}>
                {t('Summary.More.Button')}
            </NavLink>
        </article>)}
        {elements &&
            <Paginator pagination={elements} page={page} setPage={setPage}></Paginator>}
    </div>
}