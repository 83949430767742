import {Group, Module, Page, Product, ReleaseNote, Theme, Type, Version} from "./data";
import {RootNode} from "@amcon/cms-blocks";
import qs from "qs";

export type Filter = {
    readonly modules?: string[]
    readonly products?: string[]
    readonly versions?: string[]
}

export type Query = {
    filter: Filter
    limit?: number
    page?: number
    sort?: string
}

export interface Pagination<T> {
    items: T[]
    limit: number
    page: number
    totalItems: number
    totalPages: number
}

type Where = { [key: string]: { [key: string]: unknown } } | { and: Where[] } | { or: Where[] }

function buildQuery(query: Query, locale: string): string {

    const where: Where = {and: []}

    if (query.filter.products?.length)
        where.and.push({
            or: [{"product": {in: query.filter.products}}, {"product.name": {in: query.filter.products}}]
        })

    if (query.filter.modules?.length)
        where.and.push({
            or: [{"module": {in: query.filter.modules}}, {"module.name": {in: query.filter.modules}}]
        })

    if (query.filter.versions?.length)
        where.and.push({
            or: [{"version": {in: query.filter.versions}}, {"version.name": {in: query.filter.versions}}]
        })

    return qs.stringify(Object.assign({}, query, {filter: undefined, where, locale}))
}

type Response<T> = {
    docs: T[]
    hasNextPage: boolean
    hasPrevPage: boolean
    limit: number
    nextPage: number | null
    page: number
    pagingCounter: number
    prevPage: number | null
    totalDocs: number
    totalPages: number
}

export type NoteDTO = {
    type: Type
    content: RootNode
}

export type ReleaseNoteDTO = {
    id: string
    modules: Module[]
    notes: NoteDTO[]
    version: Version
    products: Product[]
}

export class ReleaseNotesService {

    constructor(private readonly baseUrl: string = "") {
    }

    async getPages(navigation: string, locale: string = navigator.language): Promise<Page[]> {

        const query = qs.stringify({where: {navigation: {equals: navigation}}, locale: this.convertLocale(locale)})
        const response = await fetch(`${this.baseUrl}/api/pages?${query}`);

        this.throwOnError(response)

        return (await response.json()).docs
    }

    async getProducts(limit = 0, page = 1, locale: string = navigator.language): Promise<Pagination<Product>> {

        const query = qs.stringify({
            limit,
            page,
            locale: this.convertLocale(locale)
        })

        const response = await fetch(`${this.baseUrl}/api/products?${query}`);

        this.throwOnError(response)

        return this.transformToPagination(await response.json())
    }

    async getReleaseNotes(query: Query, signal?: AbortSignal, locale: string = navigator.language): Promise<Pagination<ReleaseNote>> {

        const response = await fetch(`${this.baseUrl}/api/release-notes?${buildQuery(query, this.convertLocale(locale))}`, {
            signal,
            credentials: 'include'
        });

        this.throwOnError(response)

        return this.transformToPagination(await response.json())
    }

    async getSummary(limit: number = 10, page: number = 1, locale: string = navigator.language): Promise<Pagination<Group>> {

        let query = qs.stringify({limit, page, locale: this.convertLocale(locale)})
        const response = await fetch(`${this.baseUrl}/api/release-notes/summary?${query}`);

        this.throwOnError(response)

        return this.transformToPagination(await response.json())
    }

    async getTheme(): Promise<Theme> {

        const response = await fetch(`${this.baseUrl}/api/globals/theme`)

        this.throwOnError(response)

        return await response.json()
    }

    async getVersions(limit = 0, page = 1, locale: string = navigator.language): Promise<Pagination<Version>> {

        const query = qs.stringify({
            limit,
            page,
            locale: this.convertLocale(locale)
        })

        const response = await fetch(`${this.baseUrl}/api/versions?${query}`);

        this.throwOnError(response)

        return this.transformToPagination(await response.json())
    }

    private convertLocale(locale: string) {
        switch (locale.split("-")[0]) {
            case 'de':
                return 'de'
            default:
                return 'en'
        }
    }

    private throwOnError(response: globalThis.Response) {

        if (!response.ok)
            throw {code: response.status, message: response.statusText}
    }

    private transformToPagination<T>(data: Response<T>) {
        return {
            items: data.docs,
            limit: data.limit,
            page: data.page,
            totalItems: data.totalDocs,
            totalPages: data.totalPages
        }
    }
}