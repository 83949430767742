import {RootNode} from './content'

export function isEmpty(node: RootNode | null) {
    if (node == null)
        return true

    if (node.root == null)
        return true

    if (node.root.children == null || node.root.children.length == 0)
        return true

    if (node.root.children.length === 1) {

        const firstChild = node.root.children[0]

        if (firstChild != null && firstChild.type == 'paragraph') {

            if (firstChild.children == null || firstChild.children.length == 0)
                return true

            const child = firstChild.children[0]
            if (child != null && child.type === 'text' && !child.text)
                return true
        }
    }

    return false
}