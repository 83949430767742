import style from "./Chips.module.scss";

export interface ToString {
    toString(): string
}

export const Chip = <T extends ToString, >({item, onRemove, color = 'gray', icon = 'close', className = ''}: {
    item: T,
    onRemove: (value: T) => void,
    color?: 'gray' | 'red' | 'orange' | 'green',
    icon?: string
    className?: string
}) => {
    return <li className={`${style[color]} ${className}`} onClick={_ => onRemove(item)}>
        <div className={style.name}>{item.toString()}</div>
        <button className={style.close + ' material-symbols-rounded'}>
            {icon}
        </button>
    </li>
}

export const Chips = ({children}: { children: React.ReactNode[] }) => {
    return <ul className={style.chips}>
        {children}
    </ul>
}