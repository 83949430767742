import {useSearchParams} from "react-router-dom";
import {ReleaseNoteComponent} from "./ReleaseNote";
import {PlaceholderComponent} from "../components/placeholder/PlaceholderComponent";
import {useReleaseNotes} from "./useReleaseNotes";
import style from "./ReleaseNotes.module.scss"
import {Paginator} from './paginator/Paginator'
import {useCallback, useEffect, useRef} from "react";
import {Filter} from '../components/header/filter/Filter'
import {useTranslation} from 'react-i18next'

export function ReleaseNotesComponent() {

    const [searchParams, setSearchParams] = useSearchParams();

    const {t} = useTranslation()
    const page = parseInt(searchParams.get("page") || "1")
    const limit = parseInt(searchParams.get("limit") || "10")
    const {loading, releaseNotes} = useReleaseNotes(page, limit)
    const filterOverlayRef = useRef(null)

    const setPage = useCallback((number: number) => {

        if (parseInt(searchParams.get("page")) === number || (!searchParams.has("page") && number === 1))
            return

        setSearchParams((params) => {

            params.set("page", number + "")
            return params
        })
    }, [searchParams, setSearchParams])

    useEffect(() => {
        if (releaseNotes.totalPages === -1)
            return

        setPage(Math.min(releaseNotes.totalPages, parseInt(searchParams.get("page")) || 1))
    }, [releaseNotes.totalPages, searchParams, setPage]);

    return <>
        <div className="stack" ref={filterOverlayRef}>
            <div>
                <Filter overlay={filterOverlayRef.current}/>
                {releaseNotes.items.length === 0 && <h2 className={style.empty}>{t('ReleaseNotes.Empty.Text')}</h2>}
                <div className={style.notes}>
                    {loading && <>
                        <PlaceholderComponent image={{width: '50%'}}/>
                        <PlaceholderComponent/>
                        <PlaceholderComponent/>
                        <PlaceholderComponent image={{width: '30%'}}/>
                        <PlaceholderComponent/>
                        <PlaceholderComponent image={{width: '40%'}}/>
                    </>}
                    {!loading && releaseNotes.items.length > 0 && <>
                        {releaseNotes.items.map((it, i) =>
                            <ReleaseNoteComponent key={i} item={it}></ReleaseNoteComponent>)}
                    </>}
                    <Paginator page={page} setPage={setPage} pagination={releaseNotes}/>
                </div>
            </div>
        </div>
    </>
}