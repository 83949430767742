import {NavLink} from "react-router-dom";
import logo from "../../amcon_logo_2021_rgb.svg";
import style from "./HeaderComponent.module.scss";
import {useContext, useEffect, useState} from "react";
import ThemeContext from '../../ThemeContext'
import ReleaseNotesServiceContext from '../../ReleaseNotesServiceContext'
import {Page} from '@amcon/release-notes-api'
import {useTranslation} from 'react-i18next'
import {SliderComponent} from '../slider/SliderComponent'

export function HeaderComponent() {

    const {i18n, t} = useTranslation()
    const context = useContext(ThemeContext)
    const service = useContext(ReleaseNotesServiceContext)

    const [pages, setPages] = useState<Page[]>([])

    useEffect(() => {
        service.getPages('header', i18n.resolvedLanguage).then(setPages)
    }, [service, i18n.resolvedLanguage]);

    const header = <div className={style.images}>
        <SliderComponent images={context.header.images} delay={context.header.delay} duration={context.header.duration}></SliderComponent>
        <div className={style.path}></div>
        <div className={style.path2}></div>
        <div className={style.overlay}>
            <div className={style.sub}>{t('Header.Title.Small')}</div>
            <div className={style.title}>Release Notes</div>
        </div>
    </div>

    return <>
        <header className={style.header}>
            <div className={style.top}>
                <div>
                    <NavLink to={"/"}><img src={logo} width={187} height={60} className={style.logo} alt="logo"/></NavLink>
                </div>
                {/*<LocaleSwitcherComponent/>*/}
                <nav className={style.navigation}>
                    {pages.map(it => <a key={it.id} href={it.redirect}>{it.title}</a>)}
                </nav>
            </div>
            {context.header && header}
        </header>
    </>
}