import {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import style from "./Filter.module.scss";
import ReleaseNotesServiceContext from '../../../ReleaseNotesServiceContext'
import {Product, Version} from '@amcon/release-notes-api'
import {Chip, Chips, ToString} from './chips/Chips'
import {FilterContext} from './FilterContext'
import {useSearchParams} from 'react-router-dom'
import {Sort} from './sort/Sort'
import {CustomSwitch} from './switch/CustomSwitch'
import {useTranslation} from 'react-i18next'
import {createPortal} from 'react-dom'

class IdSet {

    private items: FilterValue[] = []

    add(value: FilterValue): this {
        if (!this.has(value))
            this.items.push(value)
        return this
    }

    has(value: FilterValue): boolean {
        return this.items.find(it => it.id === value.id) != null
    }

    public toArray() {
        return Array.from(this.items)
    }
}

const FilterItem = ({text, onClick, checked}: { text: string, onClick: () => void, checked: boolean }) => {
    return <li className={checked ? style.checked : ''} onClick={onClick}>{text}</li>
}

type FilterValue = {
    id: string,
    name: string
}

class ChipItem<T extends FilterValue> implements ToString {

    constructor(public delegate: T, public readonly key: string) {
    }

    get id() {
        return this.delegate.id
    }

    toString() {
        return this.delegate.name
    }
}

export const Filter = ({overlay}) => {

    const {t, i18n} = useTranslation()
    const service = useContext(ReleaseNotesServiceContext)
    const filter = useContext(FilterContext)
    const [searchParams, setSearchParams] = useSearchParams()
    const [showFilter, setShowFilter] = useState(false)
    const [products, setProducts] = useState<Product[]>([])
    const [versions, setVersions] = useState<Version[]>([])

    const [versionFrom, setVersionFrom] = useState<string | undefined>(getVersionFrom())
    const [versionTo, setVersionTo] = useState<string | undefined>(getVersionTo())
    const [filterVersion, setFilterVersion] = useState(versionFrom != undefined)
    const versionInitialSet = useRef(false)

    const filteredProducts = useMemo(() =>
            products.filter(it => filter.products && filter.products.includes(it.id)).map(it => new ChipItem(it, 'product'))
        , [filter, products])
    const filteredVersions = useMemo(() =>
            versions.filter(it => filter.versions && filter.versions.includes(it.id)).map(it => new ChipItem(it, 'version'))
        , [filter, versions])
    const versionChip = useMemo(() => {

        if (!filteredVersions.length)
            return null

        if (filteredVersions.length === 1)
            return filteredVersions[0]

        const name = `${filteredVersions[filteredVersions.length - 1].toString()}   bis   ${filteredVersions[0].toString()}`
        return new ChipItem({id: "", name}, 'version')
    }, [filteredVersions])

    const availableModules = useMemo(() => {
        return filteredProducts.reduce<IdSet>((data, product) => {
            product.delegate.modules.forEach(it => data.add(it))
            return data
        }, new IdSet()).toArray()
    }, [filteredProducts])

    const filteredModules = useMemo(() =>
            availableModules.filter(it => filter.modules && filter.modules.includes(it.id)).map(it => new ChipItem(it, 'module'))
        , [filter, availableModules])

    const hasFilters = useMemo(() =>
            Boolean(filteredProducts.length) || Boolean(filteredModules.length) || Boolean(filteredVersions.length)
        , [filteredProducts, filteredModules, filteredVersions])

    const removeAllItem = {
        toString() {
            return t('Filter.RemoveAll.Button')
        }
    }

    function getVersionFrom() {
        return searchParams.getAll("version")[0]
    }

    function getVersionTo() {
        let all: string[] = searchParams.getAll("version")
        return all[all.length - 1]
    }

    function removeFilter(value: ChipItem<any>) {
        setSearchParams(p => {
            p.delete(value.key, value.id)

            p.delete("page")

            if (value.key === "product" && !p.has(value.key))
                p.delete("module")

            return p
        })
    }

    function clearFilter() {
        setFilterVersion(false)
        setSearchParams(p => {

            p.delete("product")
            p.delete("version")
            p.delete("module")
            p.delete("page")

            return p
        })
    }

    function selectAll(key: string, items: FilterValue[]) {
        setSearchParams(p => {

            let hasAll = true

            for (let product of items) {
                if (!p.has(key, product.id)) {
                    hasAll = false
                    break
                }
            }

            p.delete("page")
            p.delete(key)
            if (!hasAll)
                items.forEach(it => p.append(key, it.id))

            return p
        })
    }

    function select(key: string, it: FilterValue) {
        setSearchParams(p => {

            if (p.has(key, it.id))
                p.delete(key, it.id)
            else
                p.append(key, it.id)

            if (key === "product" && !p.has(key))
                p.delete("module")

            p.delete("page")
            return p
        })
    }

    function clearVersion() {
        selectAll('version', [])
        setFilterVersion(false)
    }

    useEffect(() => {
        service.getProducts(0, 1, i18n.resolvedLanguage).then(res => setProducts(res.items))
        service.getVersions(50, 1, i18n.resolvedLanguage).then(res => setVersions(res.items))
    }, [service]);

    useEffect(() => {

        if (!versions.length)
            return

        if (versionInitialSet.current)
            return

        versionInitialSet.current = true

        if (filter.versions?.length) {
            setVersionTo(filter.versions[0])
            setVersionFrom(filter.versions[filter.versions.length - 1])
            setFilterVersion(true)
        } else {

            setVersionTo(versions[0]?.id)
            setVersionFrom(versions[0]?.id)
            setFilterVersion(false)
        }
    }, [filter.versions, versions]);

    const versionParamsAreSame = useCallback((items: Version[]) => {

        const missing = Boolean(items.find(it => !searchParams.has("version", it.id)))
        const sameLength = searchParams.getAll("version").length === items.length

        return !missing && sameLength
    }, [searchParams])

    useEffect(() => {

        if (!versions.length)
            return

        if (!searchParams.has("version") && !filterVersion)
            return

        const start = versions.findIndex(it => it.id === versionTo)
        const end = versions.findIndex(it => it.id === versionFrom)
        const items = versions.slice(start, end + 1)

        if (filterVersion && versionParamsAreSame(items))
            return

        setSearchParams(p => {

            p.delete("version")
            if (filterVersion)
                items.forEach(it => p.append("version", it.id))

            return p
        })

    }, [versionFrom, versionTo, filterVersion, versions, setSearchParams, searchParams, versionParamsAreSame]);

    const filterButton = <button className={style['filter-button']} onClick={_ => setShowFilter(!showFilter)}>
        <span className="material-symbols-rounded">tune</span>
        {t('Filter.Name.Button')}
    </button>

    const versionFromSelect = <select value={versionFrom} onChange={e => setVersionFrom(e.target.value)}>
        {versions.map(it => <option key={it.id} value={it.id}>{it.name}</option>)}
    </select>
    const versionToSelect = <select value={versionTo} onChange={e => setVersionTo(e.target.value)}>
        {versions.map(it => <option key={it.id} value={it.id}>{it.name}</option>)}
    </select>

    return <div className={style.root}>
        {showFilter && <div className={style.backdrop} onClick={_ => setShowFilter(false)}></div>}
        <div className={style.filter}>
            <div className={style.header}>
                {filterButton}
            </div>
            {showFilter && createPortal(<div className={style.overlay}>
                <div className={style.header}>
                    {filterButton}
                    <button className={style.close + ' material-symbols-rounded'} onClick={_ => setShowFilter(false)}>
                        close
                    </button>
                </div>
                <div className={style.content}>
                    <div>
                        <div className={style.group + ' ' + style.version}>
                            <h4>Version</h4>
                            <div>
                                {t('Filter.Version.Prefix')} {versionFromSelect} {t('Filter.Version.Middle')} {versionToSelect} {t('Filter.Version.Suffix')}
                                <CustomSwitch checked={filterVersion} onChange={setFilterVersion}/>
                            </div>
                        </div>
                        <div className={style.group}>
                            <h4>{t('Filter.Products.Headline')}</h4>
                            <ul>
                                <FilterItem checked={filteredProducts.length === products.length} onClick={() => selectAll('product', products)} text={t('Filter.Products.All.Text')}/>
                                {products.map(it =>
                                    <FilterItem checked={filteredProducts.find(chip => chip.id === it.id) != null} onClick={() => select('product', it)} key={it.id} text={it.name}/>)}
                            </ul>
                        </div>
                        <div className={style.group}>
                            <h4>{t('Filter.Modules.Headline')}</h4>
                            {!Boolean(availableModules.length) && <p>{t('Filter.Modules.Hint.Text')}</p>}
                            {Boolean(availableModules.length) && <ul>
                                <FilterItem checked={filteredModules.length === availableModules.length} onClick={() => selectAll('module', availableModules)} text={t('Filter.Modules.All.Text')}/>
                                {availableModules.map(it =>
                                    <FilterItem checked={filteredModules.find(chip => chip.id === it.id) != null} onClick={() => select('module', it)} key={it.id} text={it.name}/>)}
                            </ul>}
                        </div>
                    </div>
                </div>
            </div>, overlay)}
        </div>
        <div className={style.options}>
            <div>
                {!hasFilters && <div className={style.placeholder}>Keine Filter aktiv</div>}
                {hasFilters && <Chips>
                    {
                        <Chip className={style.remove} color="red" icon={'delete_forever'} item={removeAllItem} onRemove={clearFilter}/>}
                    {versionChip && <Chip color={'green'} item={versionChip} onRemove={clearVersion}/>}
                    {filteredProducts.map(it =>
                        <Chip color={'green'} key={it.id} item={it} onRemove={it => removeFilter(it)}/>)}
                    {filteredModules.map(it =>
                        <Chip color={'green'} key={it.id} item={it} onRemove={it => removeFilter(it)}/>)}
                </Chips>}
            </div>
            <Sort/>
        </div>
    </div>
}