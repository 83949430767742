import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {Outlet, useSearchParams} from "react-router-dom";
import {HeaderComponent} from "./components/header/HeaderComponent";
import ThemeContext from './ThemeContext'
import {Filter, Theme} from '@amcon/release-notes-api'
import ReleaseNotesServiceContext from './ReleaseNotesServiceContext'
import {FilterContext} from './components/header/filter/FilterContext'
import {FooterComponent} from './components/footer/FooterComponent'
import style from "./App.module.scss";
import AppContentContext from './appContentContext'

function App() {

    const [searchParams] = useSearchParams()
    const service = useContext(ReleaseNotesServiceContext)

    const createFilter = useCallback((prev?: Filter) => {
        const products = searchParams.getAll("product")
        const modules = searchParams.getAll("module")
        const versions = searchParams.getAll("version")

        const current: Filter = {
            products,
            modules,
            versions
        }

        return JSON.stringify(prev) === JSON.stringify(current) ? prev : current
    }, [searchParams])
    const [theme, setTheme] = useState<Theme>({header: {images: [], delay: 5000, duration: 1000}, copyRight: ""})
    const [filter, setFilter] = useState<Filter>(createFilter())
    const contentRef = useRef<HTMLDivElement>()

    useEffect(() => {
        service.getTheme().then(setTheme)
    }, [service]);

    useEffect(() => setFilter(createFilter), [searchParams, createFilter]);

    return (
        <ThemeContext.Provider value={theme}>
            <FilterContext.Provider value={filter}>
                <div className={`${style.root} content`}>
                    <HeaderComponent/>
                    <AppContentContext.Provider value={contentRef}>
                        <main ref={contentRef} className={`${style.content} full`}>
                            <Outlet/>
                        </main>
                    </AppContentContext.Provider>
                    <FooterComponent/>
                </div>
            </FilterContext.Provider>
        </ThemeContext.Provider>
    );
}

export default App;
