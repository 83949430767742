import logo from "./amcon_logo_footer.svg";
import style from "./FooterComponent.module.scss";
import ThemeContext from '../../ThemeContext'
import {useContext, useEffect, useState} from "react";
import ReleaseNotesServiceContext from '../../ReleaseNotesServiceContext'
import {Page} from '@amcon/release-notes-api'
import {useTranslation} from 'react-i18next'

export const FooterComponent = () => {

    const {i18n} = useTranslation()
    const Theme = useContext(ThemeContext)
    const service = useContext(ReleaseNotesServiceContext)

    const [pages, setPages] = useState<Page[]>([])

    useEffect(() => {
        service.getPages('footer', i18n.resolvedLanguage).then(setPages)
    }, [service, i18n.resolvedLanguage]);

    return <footer className={`${style.footer} full`}>
        <div className={style.content}>
            <a href="https://amcon.de"><img src={logo} width="149" height="48" alt={'Logo'}/></a>
            <div className={style.copyright}>{Theme.copyRight}</div>
            <nav className={style.navigation}>
                {pages.map(it => <a key={it.id} href={it.redirect}>{it.title}</a>)}
            </nav>
        </div>
    </footer>
}