import {useContext, useEffect, useMemo, useRef, useState} from "react";
import {Pagination, Query, ReleaseNote} from '@amcon/release-notes-api'
import ReleaseNotesServiceContext from '../ReleaseNotesServiceContext'
import {FilterContext} from '../components/header/filter/FilterContext'
import {useSearchParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

export function useReleaseNotes(page: number, limit: number) {

    const {i18n} = useTranslation()
    const controller = useRef(new AbortController());
    const timer = useRef<NodeJS.Timeout>()
    const service = useContext(ReleaseNotesServiceContext)
    const filter = useContext(FilterContext)
    const [searchParams] = useSearchParams()

    const defaultPagination = useMemo<Pagination<ReleaseNote>>(() => {
        return {
            totalItems: 0,
            totalPages: -1,
            items: [],
            limit: -1,
            page: -1
        }
    }, [])

    const [releaseNotes, setReleaseNotes] = useState<Pagination<ReleaseNote>>(defaultPagination)
    const [loading, setLoading] = useState(true)

    useEffect(() => {

        const fetch = () => {

            if (timer)
                clearTimeout(timer.current)

            timer.current = setTimeout(() => setLoading(true), 350)
            controller.current.abort('cancel')

            function handleError(reason: any) {

                console.error(reason)
                clearTimeout(timer.current)
                setReleaseNotes(defaultPagination)
                setLoading(reason.code !== undefined && reason.code === 20)
            }

            function handleSuccess(data: Pagination<ReleaseNote>) {

                clearTimeout(timer.current)
                setReleaseNotes(data)
                setLoading(false)
            }

            controller.current = new AbortController()

            const query: Query = {
                filter,
                page,
                limit,
                sort: searchParams.get("sort")
            }

            service.getReleaseNotes(query, controller.current.signal, i18n.resolvedLanguage).then(handleSuccess).catch(handleError)
        }

        fetch()

        return () => controller.current.abort('cancel')

    }, [filter, page, limit, defaultPagination, searchParams, service, i18n.resolvedLanguage])

    return {loading, releaseNotes}
}