import {CustomSelect} from '../select/CustomSelect'
import {ReactNode, useState} from 'react'
import {useSearchParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

interface SortType {
    value: string,
    display: string
}

export const Sort = () => {

    const {t} = useTranslation()

    const sortTypes: SortType[] = [
        {
            value: "-version.release",
            display: t('Sort.Label.Version.DESC')
        },
        {
            value: "version.release",
            display: t('Sort.Label.Version.ASC')
        },
        {
            value: "-product.name",
            display: t('Sort.Label.Product.DESC')
        },
        {
            value: "product.name",
            display: t('Sort.Label.Product.ASC')
        },
        {
            value: "-module.name",
            display: t('Sort.Label.Module.DESC')
        },
        {
            value: "module.name",
            display: t('Sort.Label.Module.ASC')
        },
    ]

    const [searchParams, setSearchParams] = useSearchParams()
    const [value, setValue] = useState(sortTypes.find(it => it.value === searchParams.get("sort")))



    function display(value: SortType, display: boolean): ReactNode {
        return <>{display ? t('Sort.Display.Text', {value:value?.display}) : value?.display}</>
    }

    function setSort(value: SortType) {
        setSearchParams(p => {
            p.set("sort", value.value)
            return p
        })
        setValue(value)
    }

    return <CustomSelect value={value} onChange={setSort} items={sortTypes} option={display}></CustomSelect>
}