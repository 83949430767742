import style from './Paginator.module.scss'
import {Pagination} from '@amcon/release-notes-api'
import {useContext} from 'react'
import AppContentContext from '../../appContentContext'

interface PaginatorProps {
    page: number
    pagination: Pagination<any>
    setPage: (value: number) => void
}

export const Paginator = ({pagination, page, setPage}: PaginatorProps) => {

    const pages: number = Math.ceil(pagination.totalItems / pagination.limit)

    const appContent = useContext(AppContentContext)

    function switchPage(page: number) {
        window.scroll({top: appContent.current.offsetTop})
        setPage(page)
    }

    const prev = []
    const next = []

    for (let number = Math.max(1, page - 3); number < page; number++)
        prev.push(number)

    for (let number = page + 1; number <= Math.min(pages, page + 3); number++)
        next.push(number)

    const more = <div className={style.more}>...</div>

    return <div className={style.paginator}>
        <button aria-label={'Vorherige Seite'} className={style.arrow + ' material-symbols-rounded'} disabled={page <= 1} onClick={_ => switchPage(page - 1)}>
            chevron_left
        </button>
        {page > 4 ? more : null}
        {prev.map(it => <button onClick={_ => switchPage(it)} key={it}>{it}</button>)}
        <button disabled={true} className={style.active}>{page}</button>
        {next.map(it => <button onClick={_ => switchPage(it)} key={it}>{it}</button>)}
        {page < pages - 3 ? more : null}
        <button aria-label={'Nächste Seite'} className={style.arrow + ' material-symbols-rounded'} disabled={page >= pages} onClick={_ => switchPage(page + 1)}>
            chevron_right
        </button>
    </div>
}