import {Navigate, useSearchParams} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import qs from 'qs'

export function ShareComponent() {

    const [params] = useSearchParams()
    const [query, setQuery] = useState('')

    useEffect(() => {

        const query: any = {}
        for (let entry of params) {

            if (!query[entry[0]])
                query[entry[0]] = [entry[1]]
            else
                query[entry[0]].push(entry[1])
        }

        fetch('/api/release-notes/create-filter-query' + qs.stringify(query, {addQueryPrefix: true}))
            .then(r => r.json())
            .then(r => setQuery(`?${qs.stringify(r, {arrayFormat: 'repeat'})}`))
    }, [params]);

    console.log(query)

    if (query)
        return <Navigate to={'/list' + query} replace/>;

    return "Erstelle Filter..."
}
