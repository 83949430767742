import style from "./PlaceholderComponent.module.scss";

export type PlacerholderDefinition = {
    image?: { width: string }
    lines?: string[]
}

export function PlaceholderComponent({image, lines}: PlacerholderDefinition) {

    if (!lines)
        lines = [
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren",
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy",
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna"
        ]

    const imageNode = image ? <figure>
        <div style={image} className={style.item + ' ' + style.image}></div>
        <div className={style.item + ' ' + style.caption}>Lorem ipsum dolor sit amet</div>
    </figure> : ''

    return <div className={`placeholder`}>
        <div className="card">
            <div className={style.header}>
                <div className={`${style.title} ${style.item}`}>&nbsp;</div>
                <div className={`${style.item} ${style.tag}`}>&nbsp;</div>
            </div>
            {lines?.map((it, i) => <p key={i} className={style.item + ' ' + style.content}>{it}</p>)}
            {imageNode}
            <div className={style.footer}>
                <div className={style.item + ' ' + style.type}>AMCON.SYSTEM | ALLGEMEIN</div>
                <div className={style.item + ' ' + style.version}>Version 2023-01-01</div>
            </div>
        </div>
    </div>
}