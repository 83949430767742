import {PayloadImageData, PayloadImageMetaData, UploadBlockNode} from '../content'
import {createRef, CSSProperties, useEffect, useState} from 'react'
import {useKeyDown} from '../useKeyDown'

function encode(baseUrl: string, value: string) {

    let strings: string[] = value.split('/')
    const name = strings.pop()

    return `${baseUrl}${strings.join('/')}/${encodeURIComponent(name)}`
}

function getSrcSet(baseUrl: string, image: PayloadImageMetaData) {

    const sizes = [`${encode(baseUrl, image.url)} ${image.width}w`]

    if (image.sizes?.thumbnail) {
        const thumbnail = image.sizes?.thumbnail
        sizes.push(`${encode(baseUrl, thumbnail.url)} ${thumbnail.width}w`)
    }
    if (image.sizes?.medium) {
        const medium = image.sizes?.medium
        sizes.push(`${encode(baseUrl, medium.url)} ${medium.width}w`)
    }
    if (image.sizes?.small) {
        const small = image.sizes?.small
        sizes.push(`${encode(baseUrl, small.url)} ${small.width}w`)
    }

    return sizes.join(", ")
}

function getScrollParent(node: HTMLElement | null): HTMLElement | null {
    if (node == null) {
        return null;
    }

    let overflowY = window.getComputedStyle(node).overflowY;
    let isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';

    if (isScrollable) {
        return node;
    } else {
        return getScrollParent(node.parentElement);
    }
}

export const ImageComponent = ({item, baseUrl}: { item: UploadBlockNode, baseUrl: string }) => {

    const [show, setShowing] = useState(false)
    const [style, setStyle] = useState<CSSProperties>({})
    const button = createRef<HTMLDivElement>()
    const overlayRef = createRef<HTMLDivElement>()
    const overlayContentRef = createRef<HTMLDivElement>()

    const image: PayloadImageData = item.value.sizes?.thumbnail || item.value

    useKeyDown(() => {
        setShowing(false)
    }, ["Escape"]);

    const overlay = show &&
        <div ref={overlayRef} className={`payload-block-image-overlay`} onClick={_ => setShowing(false)}>
            <div className={`payload-block-image-overlay-title`} onClick={e => e.stopPropagation()}>
                <div>{item.value.caption}</div>
                <div onClick={_ => setShowing(false)} className={`payload-block-image-overlay-close`}>
                    <span className="material-symbols-rounded">close</span>
                </div>
            </div>
            <div ref={overlayContentRef}></div>
        </div>

    function close() {
        setShowing(false)
    }

    useEffect(() => {

        const parent = getScrollParent(button.current) || document.body.parentElement
        if (!show) {
            parent?.removeEventListener('scroll', close);
            parent?.removeEventListener('resize', close);
            window.removeEventListener('scroll', close);
            window.removeEventListener('resize', close);
            setStyle({})
            return
        }

        window.addEventListener('scroll', close);
        window.addEventListener('resize', close);
        parent?.addEventListener('scroll', close);
        parent?.addEventListener('resize', close);

        overlayRef.current!.style.top = (parent?.scrollTop || 0) + "px"
        const contentRect = overlayContentRef.current!.getBoundingClientRect()
        const imageWidth = Math.min(item.value.width, contentRect.width)
        const imageHeight = Math.min(item.value.height, contentRect.height)
        const scale = Math.min(imageWidth / image.width, imageHeight / image.height)

        const rect = button.current!.getBoundingClientRect()

        const xCenter = -rect.x - image.width / 2 + contentRect.width / 2 + contentRect.left
        const yCenter = -rect.y - image.height / 2 + contentRect.height / 2 + contentRect.top

        setStyle({transform: `scale(${scale}) translate(${xCenter / scale}px, ${yCenter / scale}px)`})

    }, [show, overlayContentRef.current]);

    return <figure className={`payload-block-image${show ? ' showing' : ''}`}>
        <div ref={button} role={'button'} className={`payload-block-image-button`} onClick={_ => setShowing(!show)}>
            <picture>
                <source srcSet={getSrcSet(baseUrl, item.value)}/>
                <img className={`payload-block-image`} src={baseUrl + image.url} alt={item.value.alt}
                     width={image.width}
                     height={image.height}
                     style={style}
                     loading={'lazy'}/>
            </picture>
            <figcaption>{item.value.caption}</figcaption>
        </div>
        {show ? overlay : null}
    </figure>
}