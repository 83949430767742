import App from "./App";
import {ReleaseNotesComponent} from "./release-note/ReleaseNotesComponent";
import {Navigate, RouteObject} from "react-router-dom";
import {PreviewComponent} from './components/live-preview/PreviewComponent'
import {SummaryComponent} from './components/summary/SummaryComponent'
import {ShareComponent} from './share/ShareComponent'

export const routes: RouteObject[] = [
    {
        path: "/",
        element: <App/>,
        children: [{
            path: "/",
            element: <SummaryComponent/>
        }, {
            path: "list",
            element: <ReleaseNotesComponent/>
        }, {
            path: "preview/:id",
            element: <PreviewComponent/>
        }, {
            path: "share",
            element: <ShareComponent/>
        }]
    },
    {
        path: '*',
        element: <Navigate to="/" replace/>
    }
]