import {InlineNode, RootChild} from './content'
import {createElement, ReactNode} from 'react'
import {ImageComponent} from "./components/ImageComponent"
import {TextComponent} from "./components/TextComponent"

export const serialize = (content: RootChild[] | InlineNode[], baseUrl: string = ""): ReactNode => {

    if (!content.length)
        return <>&nbsp;</>

    return <>
        {content.map((item: RootChild | InlineNode, index: number): ReactNode => {

            if (item.type === 'text')
                return <TextComponent key={index} item={item}/>
            if (item.type === 'linebreak')
                return <br key={index}/>
            if (item.type === 'link' || item.type === 'autolink')
                return <a key={index} href={item.fields.url} target={item.fields.newTab ? '_blank' : '_self'} rel="noreferrer">
                    {serialize(item.children, baseUrl)}
                </a>
            if (item.type === 'listitem')
                return <li value={item.value} key={index} className={item.checked ? `payload-block-list-checked` : ""}>
                    {serialize(item.children, baseUrl)}
                </li>

            if (item.type === 'paragraph')
                return <p key={index} role={"paragraph"} style={{paddingInlineStart: 40 * (item.indent || 0)}}>
                    {serialize(item.children, baseUrl)}
                </p>
            if (item.type === 'quote')
                return <div key={index} className={`payload-block-quote`} style={{paddingInlineStart: 20 + 40 * (item.indent || 0)}}>
                    {serialize(item.children, baseUrl)}
                </div>
            if (item.type === 'heading')
                return createElement(item.tag, {
                    className: 'payload-block-heading',
                    key: index
                }, serialize(item.children, baseUrl))
            if (item.type === 'list')
                return createElement(item.tag, {
                    className: `payload-block-list-${item.listType}`,
                    key: index,
                    start: item.start
                }, serialize(item.children, baseUrl))
            if (item.type === 'upload' && item.value)
                return <ImageComponent key={index} item={item} baseUrl={baseUrl}/>

            return null
        })}
    </>
}
