import {createContext} from "react"
import {Theme} from '@amcon/release-notes-api'

const ThemeContext = createContext<Theme>({
    header: {
        images: [],
        delay: 5000,
        duration: 1000
    },
    copyRight: ""
})

export default ThemeContext